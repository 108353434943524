import React from 'react'
import { Card, CardBody } from 'reactstrap';
import { FaArrowTrendUp, FaArrowDown } from "react-icons/fa6";
import { SiOpenai } from "react-icons/si";
import Loader3 from '../../../../../../CommonElements/Spinner/loader3';
import { ArrowDown } from 'react-feather';
import { useNavigate } from 'react-router';

const NewAIAccuracy = ({ data, loader, filters }) => {
  let accuracyPercent = data

  const Navigate = useNavigate()

  const handleNavigate = () => {
    const aiFilters = {
      areas: [],
      shifts: [filters?.shift],
      date: "",
      week: filters?.weekly,
      month: filters?.month,
      starting: "",
      ending: ""
    }
    localStorage.setItem('aifilters', JSON.stringify(aiFilters));
    const url = `${process.env.PUBLIC_URL}/reports/${JSON.parse(localStorage.getItem('role'))}`;
    Navigate(url);
  }
  return (
    <Card onClick={handleNavigate} style={{ borderRadius: '24px', minHeight: '170px', maxHeight: 'auto', cursor: 'pointer' }}>
      {loader ? <span className="w-100 h-100 d-flex justify-content-center align-items-center position-absolute"><Loader3 /></span> :
        <CardBody className='p-4'>
          <p style={{ fontSize: '16px', color: '#383838', fontWeight: '400' }} className="ellipsis-text">AI Accuracy</p>
          <div className=' d-flex align-items-center gap-2 '>
            <SiOpenai style={{ width: '40px', height: '40px', borderRadius: '20px', padding: '5px', background: '#175FA4', color: 'white' }} />
            <p className=' m-0' style={{ color: '#595959', fontSize: '16px', fontSize: '30px', fontWeight: '500' }} >
              {accuracyPercent === "NaN"
                ? "N/A"
                : !accuracyPercent
                  ? "N/A"
                  : accuracyPercent + "%"}
            </p>
          </div>
        </CardBody>
      }
    </Card>
  )
}
export default NewAIAccuracy
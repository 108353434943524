import React, { Fragment, useCallback, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import './tableIcon.css'
const MonitorTable = ({ staticData, tableColumns, onRowClick, defaultSortFieldId, defaultSortAsc, sortServer, sortTimeStamp }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [data, setData] = useState(staticData);

  const handleRowClick = (row) => {
    if (onRowClick && typeof onRowClick === 'function') {
      onRowClick(row);
    } else {
      console.error('onRowClick is not a function');
    }
  };
  useEffect(() => {
    if (staticData) {
      if(sortTimeStamp) {
        const sortedData = [...staticData].sort((a, b) => {
          const aTimestamp = new Date(a.timestamp).getTime();
          const bTimestamp = new Date(b.timestamp).getTime();
          return defaultSortAsc ? aTimestamp - bTimestamp : bTimestamp - aTimestamp;
        });
        setData(sortedData);
      }else {
        setData(staticData);
      }
      
    }
  }, [staticData, defaultSortAsc]);
  // useEffect(() => {
  //   setData(staticData);
  // }, [staticData]);

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleDelete = () => {
    if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.name)}?`)) {
      setToggleDelet(!toggleDelet);
      setData(data.filter(item => !selectedRows.some(elem => elem.id === item.id)));
      setSelectedRows([]);
    }
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: '55px', // Override row height
      },
    },
    headCells: {
      style: {
        textAlign: 'left', // Align header text to the left
        justifyContent: 'flex-start', // Align header text content to the left
        fontSize: '16px',
        fontWeight: 'bold',
      },
    },
    cells: {
      style: {
        textAlign: 'left', // Align cell text to the left
        justifyContent: 'flex-start', // Align cell content to the left
      },
    },
  };

  const handleSort = (column, sortDirection) => {
    console.log("sortDirection",sortDirection)
    console.log("data",data)
    const sortedData = staticData && [...data]?.sort((a, b) => {
      const aTimestamp = new Date(a.timestamp).getTime();
      const bTimestamp = new Date(b.timestamp).getTime();
      return sortDirection === 'asc' ? aTimestamp - bTimestamp : bTimestamp - aTimestamp;
    });
    setData(sortedData);
  };


  return (
    <Fragment>
      {/* {selectedRows.length > 0 && (
                <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
                    <H4 attrH4={{ className: 'text-muted m-0' }}>Delete Selected Data..!</H4>
                    <Btn attrBtn={{ color: 'danger', onClick: handleDelete }}>Delete</Btn>
                </div>
            )} */}
      <DataTable
        data={data}
        columns={tableColumns}
        striped={false}
        center={true}
        pagination
        onRowClicked={handleRowClick}
        customStyles={customStyles}
        defaultSortFieldId={defaultSortFieldId}
        defaultSortAsc={defaultSortAsc}
        onSort={handleSort}
        sortServer={sortServer}

      />
    </Fragment>
  );
};

export default MonitorTable;


// import React, { useEffect, useState } from 'react'
// import AIModelAndReportsScreen from './model_and_reports'
// import { Card, CardBody, Col, Container, Row } from 'reactstrap'
// import AreaService from '../../../../api/areaService';

// export default function GlobalAIReport() {
//   const [acceptedRecords,setAcceptedRecords] = useState({});
//   const [factoryID, setfactoryID] = useState(JSON.parse(localStorage.getItem('userData')).factory.id || 0)
//   console.log('acceptedRecords', acceptedRecords)

//   function getLastFourMonths() {
//     const months = [];
//     const today = new Date();

//     for (let i = 0; i < 4; i++) {
//         let d = new Date(today.getFullYear(), today.getMonth() - i, 1);
//         let formatted = d.getFullYear() + "-" + String(d.getMonth() + 1).padStart(2, '0');
//         months.push(formatted);
//     }

//     return months;
// }
// useEffect(() => { 
//   const months = getLastFourMonths();
//   console.log('monthsff', months)
//   const month1 = months[0]
//   const month2 = months[1]
//   const month3 = months[2]
//   const month4 = months[3]
//   getAcceptedRecords(month1)
//   getAcceptedRecords(month2)
//   getAcceptedRecords(month3)
//   getAcceptedRecords(month4)
// }, [])

//   async function getAcceptedRecords(month) {
//         const pID = JSON.parse(localStorage.getItem("userData"))?.id;
//         const payload = {
//           user_id: pID,
//           identifier: 'month',
//           factory_id: factoryID,
//           filters: {
//             approval: "Select Approval",
//             module: "",
//             severity: "",
//             shift: "",
//             date: "",
//             week: "",
//             month: month,
//             starting: "",
//             ending: "",
//             area: "",
//             subarea: "",
//           },
//           pagination: {
//             page_no: 1,
//             per_page: 21,
//           },
//         };

//         try {
//           // const res = await AreaService.getModelAccuracyChart(payload);
//           const res = await AreaService.getFilterAlerts(payload);
//           if (res?.statusText?.toLocaleLowerCase() == "ok") { 
//             console.log('respdfsf', res)
//             const accepted = res?.data?.data?.accepted_records;
//             setAcceptedRecords((prevRecords) => ({
//               ...prevRecords,
//               [month]: accepted,
//             }));

//           }

//         } catch (err) {
//           console.log("Ai accuracy error", err);
//         }
//   }


//   const Mapmonths = getLastFourMonths();
//   console.log('Mapmonths', Mapmonths)
//   return (
//     <>
//       <Container fluid={true} className="dashboard-first-page">
//         <Row>
//           <h4 style={{ fontSize: "20px" }}>Verified Safety AI Violations</h4>
//           {Mapmonths.map(({ label, value }) => (
//             <Col key={value} xs="12" sm="6" md="4" lg="3" xl="3">
//               <Card>
//                 <CardBody className="p-3">
//                   <p className="pb-0 mb-0">{label}</p>
//                   <h2>{acceptedRecords[value] || 0}</h2>
//                 </CardBody>
//               </Card>
//             </Col>
//           ))}

//         </Row>
//       </Container>

//       <AIModelAndReportsScreen area={false} />
//     </>
//   )
// }


import React, { useEffect, useState } from 'react';
import AIModelAndReportsScreen from './model_and_reports';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import AreaService from '../../../../api/areaService';
import MyComponentLoader from '../../../../CommonElements/Spinner/loader3';
import { IoWarning } from 'react-icons/io5';
import './custom.css'

export default function GlobalAIReport() {
  const [acceptedRecords, setAcceptedRecords] = useState({});
  const factoryID = JSON.parse(localStorage.getItem('userData'))?.factory?.id || 0;

  console.log('acceptedRecords', acceptedRecords);

  function getLastFourMonths() {
    const months = [];
    const today = new Date();

    for (let i = 0; i < 4; i++) {
      let d = new Date(today.getFullYear(), today.getMonth() - i, 1);
      let formatted = d.getFullYear() + '-' + String(d.getMonth() + 1).padStart(2, '0');
      let label = d.toLocaleString('default', { month: 'long' }); // Month name (e.g., "March")
      months.push({ label, value: formatted });
    }

    return months;
  }

  useEffect(() => {
    const fetchData = async () => {
      const months = getLastFourMonths();
      const records = {};

      for (const { value } of months) {
        const acceptedCount = await getAcceptedRecords(value);
        records[value] = acceptedCount;
      }

      setAcceptedRecords(records); // Update state only once after fetching all months
    };

    fetchData();
  }, []);

  async function getAcceptedRecords(month) {
    const pID = JSON.parse(localStorage.getItem('userData'))?.id;
    const payload = {
      user_id: pID,
      identifier: 'month',
      factory_id: factoryID,
      filters: {
        approval: 'Select Approval',
        module: '',
        severity: '',
        shift: '',
        date: '',
        week: '',
        month: month,
        starting: '',
        ending: '',
        area: '',
        subarea: '',
      },
      pagination: {
        page_no: 1,
        per_page: 21,
      },
    };

    try {
      const res = await AreaService.getFilterAlerts(payload);
      if (res?.statusText?.toLowerCase() === 'ok') {
        console.log(`Data for ${month}:`, res?.data?.data?.accepted_records);
        return res?.data?.data?.accepted_records || 0;
      }
    } catch (err) {
      console.error('AI accuracy error', err);
    }
    return 0;
  }

  const months = getLastFourMonths();
  console.log('Mapmonths', months);

  return (
    <>
      <Container fluid={true} className="dashboard-first-page">
        <Row>
        <h4 style={{ fontSize: '20px' }}>Verified Safety AI Violations</h4>
        {months?.reverse()?.map(({ label, value }) => (
          <Col key={value} xs="12" sm="6" md="4" lg="3" xl="3">
            <Card style={{ borderRadius: '24px', minHeight: '170px', maxHeight: 'auto', display:'flex', justifyContent:'center' }}>
              {/* {loader ? <span className="w-100 h-100 d-flex justify-content-center align-items-center position-absolute"><MyComponentLoader /></span> : */}
              <CardBody className='p-4 d-flex flex-column justify-content-center align-items-center' >
                <p style={{ fontSize: '16px', color: '#383838', fontWeight: '400' }} className="ellipsis-text">{label} {value?.split('-')[0]}</p>
                <div className=' d-flex align-items-center gap-3 '>
                  {/* <SiOpenai style={{ width: '40px', height: '40px', borderRadius: '20px', padding: '5px', background: '#175FA4', color: 'white' }} /> */}
                  <IoWarning style={{ width: '40px', height: '40px', borderRadius: '50%', padding: '8px', background: '#175FA4', color: 'white' }} />
                  <p className=' m-0' style={{ color: '#595959', fontSize: '16px', fontSize: '30px', fontWeight: '500' }} >
                  {acceptedRecords[value] ?? <MyComponentLoader />}
                  </p>
                </div>
              </CardBody>
              {/* } */}
            </Card>
            </Col>
          ))}
        </Row>


        
        {/* <div className='d-flex flex-wrap gap-2'>
          {months?.reverse()?.map(({ label, value }) => (
            <Card className='ai_violation_box'>
              <CardBody className="p-3 d-flex gap-2 flex-column">
                <p className="pb-0 mb-0">{label} {value?.split('-')[0]}</p>
                <h2 className='d-flex align-items-center'>
                  <span className='me-2'><IoWarning style={{ width: '40px', height: '40px', borderRadius: '50%', padding: '8px', background: '#175FA4', color: 'white' }} /></span>
                  {acceptedRecords[value] ?? <MyComponentLoader />}</h2>
              </CardBody>
            </Card>

          ))}
        </div> */}
        

      </Container>

      <AIModelAndReportsScreen area={false} />
    </>
  );
}

